export default {
  auth: {
    builder_login: "/api/builder/login/",
    reset_password:'/api/builder/change-password/'
  },
  info: {
    builder_info: "/api/builder/add-builder-info/",
  },
  get_builder_info:"/api/builder/get-builder-info/",
  get_password: "/api/builder/generate-password/",
  search: "/api/builder/search-project/",
  project: {
    get_project_list: "/api/builder/get-project-list/",
    get_project_detail: "/api/builder/get-project-detail/",
    add_project: "/api/builder/add-project/",
    update_project: "/api/builder/update-project/",
    add_project_image: "/api/builder/add-project-image/",
    delete_doc:'/api/builder/delete-project-media/',
    add_project_docs: "/api/builder/add-project-doc/",
    delete_project: "/api/builder/delete-project/",
    is_active: "/api/builder/project-active-inactive/",
  },
  manager: {
    add_manager: "/api/builder/add-project-manager/",
    get_manager_list: "/api/builder/get-project-manager/",
    delete_manager: "/api/builder/delete-project-manager/",
    assign_manager:"/api/builder/assign-project/",
    update_manager:'/api/builder/edit-project-manager/',
    get_manager_details:'/api/builder/get-manager-details/',//?id=managerId

  },
  promoter: {
    add_promoter: "/api/builder/add-project-promoter/",
    delete_promotor: "/api/builder/delete-project-promoter/",
  },
  leads: {
    lead_list: "/api/builder/lead-list/",
    reject_lead: "/api/builder/rejected-lead-list/",
    approve_lead: "/api/builder/approved-lead-list/",
    approve_reward: "/api/builder/approve-reward/",
    reject_reward: "/api/builder/reject-reward/",
  },
  transaction: {
    transaction_pending_list: "/api/builder/pending-transaction-list/",
    approve_payment_list: "/api/builder/approved-payment-list/",
    reject_payment_list: "/api/builder/rejected-payment-list/",
    approve_payment: "/api/builder/approve-payment/",
    reject_payment: "/api/builder/reject-payment/",
  },
  count: {
  lead_count: "/api/builder/count/?type=leads",
  transaction_count: "/api/builder/count/?type=payment",
  },
  faqs:{
  get_faqs: "/api/faqs/get-faqs/",
  get_support_email:'/api/masterdata/support-email/'
  },
  ledger: {
    get_ledger_list: "/api/builder/credit-ledger-list/",
  },
  builder_credit: {
    get_builder_credit: "/api/builder/builder-credit/",
  },
  master:{
    stage_list:"/api/masterdata/list/stage",
    project_type_list:"/api/masterdata/list/project-type",
    development_type_list:"/api/masterdata/list/development-type",
    unit_list:"/api/masterdata/list/unit",
    state_list:"/api/masterdata/list/state",
    city_list:"/api/masterdata/list/city",//?state=state_id
    
  }
};