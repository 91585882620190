export default {
    state:{
        stepper_id: 1,
    },
    getters: {
        getStepperId: (state) => state.stepper_id,
    },
    mutations: {
        SET_STEPPER_ID(state, value) {
            state.stepper_id = value
        },
    },
    actions: {
        setStepperId({ commit }, value) {
            commit("SET_STEPPER_ID", value);
        },
    },
}