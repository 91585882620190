export default {
    namespaced: true,
    state: {
      projectData:{}
    },
    mutations: {
      SET_PROJECTDATA(state, data) {
        state.projectData = data;
      },
    },
    getters: {
      getProjectId: (state) => state.projectData.id,
    }  
  }