export default {
  namespaced: true,
  state: {
    builder_details: {
        object: {},
        show: false,
    },
     projectData:{}
    },
mutations: {
    SET_BUILDER_DETAILS(state, obj) {
        state.builder_details.object = obj.object ? obj.object : {};
        state.builder_details.show = obj.show;
        // state.builder_details.id = obj.id ? obj.id : '';
        // state.builder_details.type = obj.type ? obj.type : "";
    },
    setProjectData(state,data){
        state.projectData=data?data:this.projectData
    }
},
getters: {},
// getters: {
//   getBuilderDetails: (state) => state.builder_details,
// },
// actions: {
//   setBuilderDetails({ commit }, obj) {
//     commit("SET_BUILDER_DETAILS", obj);
//   },
// }
};
