const getDefaultState = () => {
    return {
      builderCredit: 0

      
    };
  };
  
  const state = getDefaultState();
  
  const mutations = {
    SET_BUILDER_CREDIT(state, builderCredit) {
      state.builderCredit = builderCredit;
    },
  
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    
  };
  