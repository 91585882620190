export default {
    state:{
      faqs:{
          object:{},
          show: false,
          type:"",
        }
      },
        mutations:{
          SET_FAQS(state, obj) {
            state.faqs.object=obj.object ? obj.object : {};
            state.faqs.show=obj.show;
            state.faqs.type = obj.type ? obj.type : "";
          },
      }, 
      getters:{},
    }