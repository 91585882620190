import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
Vue.use(Vuetify);
export default new Vuetify({
    breakpoint:{
        thresholds:{
          xs: 340,
          sm: 540,
          md: 800,
          lg: 1280
        },
       },
    theme: {
        themes: {
            light: {
                primary: {
                    base: "#2F80ED",
                },
                secondary: "#FF7C1E",

            },
        },
    },
});
