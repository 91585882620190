import Vue from "vue";
import VueRouter from "vue-router";
import authToken from "../auth/authToken";
import AuthLayout from "@/components/layout/auth/AuthLayout";
import AppLayout from "@/components/layout/app/AppLayout";
// import { component } from "vue/types/umd";
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    meta: {
      isPublic: true,
      title: "Login",
      layout: AuthLayout,
    },
    component: () => import("../views/Auth/LoginPage.vue"),
  },
  {
    path: "/reset-password",
    name: "resetpassword",
    meta: {
      isPublic: false,
      title: "Reset Password",
      layout: AuthLayout,
    },
    component: () => import("../views/Auth/Password/resetPassword.vue"),
  },
  // {
  //   path: "/password",
  //   name: "resetpassword",
  //   meta: {
  //     isPublic: true,
  //     title: "Reset Password",
  //     layout: AuthLayout,
  //   },
  //   component: () => import("../views/Auth/Password/forgotPassword.vue"),
  // },
  // {
  //   path: "/verify-otp",
  //   name: "verifyotp",
  //   meta: {
  //     isPublic: true,
  //     title: "Verify Otp",
  //     layout: AuthLayout,
  //   },
  //   component: () => import("../views/Auth/VerifyOtp.vue"),
  // },
  // {
  //   path: "/set-password",
  //   name: "setpassword",
  //   meta: {
  //     isPublic: true,
  //     title: "Set Password",
  //     layout: AuthLayout,
  //   },
  //   component: () => import("../views/Auth/Password/setPassword.vue"),
  // },
  {
    path: "/company-details",
    name: "companydetails",
    meta: {
      isPublic: true,
      title: "Company Details",
      layout: AuthLayout,
    },
    component: () => import("@/components/app/dialogs/CompanyDetails.vue"),
  },
  {
    path:"/projectdetails/:id",
    name: "project_detail",
    meta: {
      isPublic: false,
      // requiresAuth: true,
      title: "View Project",
      show_back_btn: true,
      layout: AppLayout,
    },
    component: () => import("../views/app/Projects/ViewProject.vue"),
    },
  // {
  //   path: "/stepper",
  //   name: "stepper",
  //   meta: {
  //     isPublic: true,
  //     title: "Add New Project",
  //     // show_back_btn: false,
  //     layout: AppLayout,
  //   },
  //   component: () => import("../components/stepper/Stepper.vue"),
  // },
  {
    path: "/",
    name: "project",
    meta: {
      isPublic: false,
      title: "BeBroker",
      // show_back_btn: false,
      layout: AppLayout,
    },
    component: () => import("../views/app/Projects/ProjectList.vue"),
  //   beforeEnter: (to, from, next) => {
  //     // guard(to, from, next)
  // },
//   children: [
//   {
//     path:"/projectdetails",
//     name: "project_detail",
//     meta: {
//       isPublic: false,
//       requiresAuth: true,
//       title: "View Project",
//       show_back_btn: true,
//       layout: AppLayout,
//     },
//     component: () => import("../views/app/Projects/ViewProject.vue"),

// },
//   ]
},
  // {
  //   path:"/projectdetails/:id",
  //   name: "project_detail",
  //   meta: {
  //     isPublic: false,
  //     requiresAuth: true,
  //     title: "View Project",
  //     show_back_btn: true,
  //     layout: AppLayout,
  //   }
  // },
  
  // {
  //   path: "/editproject/:id",
  //   name: "edit-project",
  //   meta: {
  //     isPublic: true,
  //     requiresAuth: true,
  //     title: "Edit Project",
  //     show_back_btn: true,
  //     layout: AppLayout,
  //   },
  //  component: ()=> import("../views/app/Projects/EditProject.vue"),
  // },
  {
    path: "/support",
    name: "support",
    meta: {
      isPublic: false,
      title: "Support",
      layout: AppLayout,
    },
    component: () => import("../views/app/Support/SupportList.vue"),
  },
  {
    path: "/transaction",
    name: "transaction",
    meta: {
      isPublic: false,
      title: "Transactions",
      layout: AppLayout,
    },
    component: () => import("../views/app/Transaction/TransactionList.vue"),
  },
  {
    path:"/ledger",
    name: "ledger",
    meta: {
      isPublic: false,
      title: "Credit-Ledger",
      layout: AppLayout,
    },
    component: () => import("../views/app/ledger/CreditLedger.vue"),
  },
  {
    path: "/lead",
    name: "lead",
    meta: {
      isPublic: false,
      title: "Project-Lead",
      layout: AppLayout,
    },
    component: () => import("../views/app/Lead/LeadList.vue"),
  },
  {
    path: "/master",
    name: "master",
    meta: {
      isPublic: false,
      title: "Project Managers",
      layout: AppLayout,
    },
    component: () => import("../views/app/master/MastersManagement.vue"),
  },
  // {
  //   path: '/project-lead',
  //   name: 'Project Lead',
  //   meta: {
  //     isPublic: true,
  //     title: "Project Lead",
  //     layout: AppLayout,
  //   },
  //   component: () => import("../views/app/Project-leads/ProjectLead.vue")
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? `${to.meta.title}` : "Be Broker";

  const { accessToken, refreshToken } = authToken.getToken();
  if (
      accessToken &&
      refreshToken &&
      (to.name === "login" && to.name === "companydetails")
  )
      next({ name: "project" });
  else if (!to.meta.isPublic) {
      if (!accessToken && !refreshToken) {
          next({ name: "login" });
      } else next();
  } else next();
});
export default router;