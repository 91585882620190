import Vue from "vue";
import Vuex from "vuex";
import app from "./module/app/app ";
import snackBar from "./module/snackBar/app";
import dialog from "./module/dialog/index";
import addproject from "./module/dialog/addproject";
import faqs from "./module/Faqs/index";
import stepper from "./module/Stepper/app"
import DeleteWarning from "./module/DeleteWarning/index"
import ledger from "./module/LedgerCredit/ledger"

// import addproject from "./module/dialog/addproject";
// import documents from "./module/dialog/documents"
Vue.use(Vuex);
const getDefaultState = () => {
  return {
    isLoggedIn: false,
    isProject: false,
  };
};
const state = getDefaultState();
const actions = {
  // resetStoreState({ commit }) {
  //   commit("RESET_STATE");
  // },
};
const mutations = {
  // RESET_STATE(state) {
  //   Object.assign(state, getDefaultState());
  // },
};
export default new Vuex.Store({
  state: state,
  mutations: mutations,
  actions: actions,
  modules: {
    app,
    snackBar,
    dialog,
    faqs,
    stepper,
    DeleteWarning,
    addproject,
    ledger
    // documents,
  },
});
