export default {
  namespaced: true,
  state: {
    loading_screen:false,
    login_email: "",
    login_password: "",
    reject_lead: {
      object: {},
      show: false,
      type: '',
      emit: '',
      id: null,
    },
    reject_payment: {
      object: {},
      show: false,
      type: '',
      emit: '',
      id: null,
    },
    project_data:{
      object: {},
      id: null, 
        
    },
    projectData:{
      name:"",
      development_type:null,
      area:"",
      unit:null,
      floor_plans:[],
      number_of_units:"",
      available_units:"",
      completion_stage:null,
      project_type:null,
      completion_date:null,
      launch_date:null,
      number_of_buildings:null,
      map_address:"",
      youtube_link:"",
      latitude:21.25,
      longitute:81.629997,
      full_address:"",
      zip:'',
      locality:"",
      ward:"",
      tehsil:"",
      patwari:"",
      halka:"",
      city:null,
      state:null,
      project_promoters:[
        {
          promoter:null
        }
      ],
      project_managers:[
        {manager:null}
      ],
      file_array:[],
      pdf_array:[],
      media_array:[],

    },
    projectModal: {
      show: false,
      id:null,
      type: "",
      projectData:{
        name:"",
        development_type:null,
        area:"",
        unit:null,
        floor_plans:[],
        number_of_units:"",
        available_units:"",
        completion_stage:null,
        project_type:null,
        completion_date:null,
        launch_date:null,
        number_of_buildings:null,
        map_address:"",
        youtube_link:"",
        latitude:21.25,
        longitute:81.629997,
        full_address:"",
        zip:'',
        locality:"",
        ward:"",
        tehsil:"",
        patwari:"",
        halka:"",
        city:null,
        state:null,
        project_promoters:[
          {
            promoter:null
          }
        ],
        project_managers:[
          {manager:null}
        ],
        file_array:[],
        pdf_array:[],
        media_array:[],

      }
    },
  },
  mutations: {
    setLoadingScreen(state,value){
      state.loading_screen=value
    },
    set_project_data(state,obj){
state.projectData=obj
    },
    SET_LOGIN_EMAIL(state, obj) {
      state.login_email = obj;
    },
    SET_LOGIN_PASSWORD(state, obj) {
      state.login_password = obj;
    },
    SET_REJECT_PAYMENT(state,obj) {
      state.reject_payment.object = obj.object ? obj.object : {};
      state.reject_payment.show = obj.show;
      state.reject_payment.id = obj.id ? obj.id : '';
      state.reject_payment.type = obj?.type ? obj.type : '';
      state.reject_payment.emit = obj.emit ? obj.emit : '';
    },
    SET_REJECT_LEAD(state, obj) {
      state.reject_lead.object = obj.object ? obj.object : {};
      state.reject_lead.show = obj.show;
      state.reject_lead.id = obj.id ? obj.id : '';
      state.reject_lead.type = obj.type ? obj.type : '';
      state.reject_lead.emit = obj.emit ? obj.emit : '';
      // console.log("ef",state.reject_lead.object )
  },
  TOGGLE_PROJECT_MODAL(state, obj){
    state.projectModal.show = obj.show
    state.projectModal.id=obj.id ? obj.id : null;
    state.projectModal.type=obj.type ? obj.type : "";
    // state.projectModal.projectData=obj.data ? obj.data :state.projectModal.projectData
  },
  // SET_PROJECT_ID(state, obj) {
  //   state.project_id.id = obj.id ? obj.id : '';
  // },
  SET_PROJECT_DATA(state, obj) {
    // console.log('store', obj)
    state.project_data.object = obj.object ? obj.object : {};
    state.project_data.id = obj.id ? obj.id : '';
  }
  },
  getters: {},
  };
