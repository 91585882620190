

<template>
    <v-dialog
        v-model="show"
        width="480px"
        persistent
        scrollable
    >
        <v-card>
            <v-card-text class="pt-10">
                <v-row no-gutters justify="center">
                    <v-col cols="3" class="d-flex justify-center">
                        <!-- <img
                            src="@/assets/deleteIcon.png"
                            alt=""
                            width="100px"
                        /> -->
                    </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                    <v-col cols="10" class="text-center">
                        <div class="text-description">
                            <span >
                            {{ title }}
                            </span>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="d-flex justify-center pb-8">
                <v-btn
                    class="text-capitalize text-btn"
                    width="45%"
                    height="46px"
                    outlined
                    color="primary"
                    text
                    @click="cancel"
                >
                    <span>Cancel</span>
                </v-btn>
                <v-btn
                    class="text-capitalize btn px-7 ml-4"
                    width="45%"
                    height="46px"
                    color="primary"
                    @click="confirm"
                >
                    <span>Confirm</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    data(){
        return{
show:false,
title:'',
        }
    },
    created() {
    this.$root.$refs.confirm = this;
  },
    methods:{
      askConfirmation({title}){
        this.show=true
        this.title=title
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      }, 
      cancel(){
        this.show=false
        this.resolve(false)
      }, 
      confirm(){
        this.show=false
        this.resolve(true)
      }, 
    }
}
</script>
<style scoped>
.text-description {
     
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;

    color: #000000;
}
.text-btn {
    border: 1px solid primary;
    box-sizing: border-box;
    border-radius: 8px;
}
.text-btn >>> span {
     
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    color: primary;
}
.btn {
    box-sizing: border-box;
    border-radius: 8px;
}
.btn >>> span {
     
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    color: #ffffff;
}
</style>




<style lang="scss" scoped>

</style>